<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#F1F4FB;"
      d="M493.037,474.074H18.963C8.489,474.074,0,465.585,0,455.111V56.889
	c0-10.473,8.489-18.963,18.963-18.963h474.074c10.473,0,18.963,8.489,18.963,18.963v398.222
	C512,465.585,503.511,474.074,493.037,474.074z"
    />
    <path
      style="fill:#D5DCED;"
      d="M113.778,37.926h379.259c10.473,0,18.963,8.489,18.963,18.963v18.963H113.778V37.926z"
    />
    <path
      style="fill:#FF507D;"
      d="M18.963,37.926h18.963v37.926H0V56.889C0,46.415,8.489,37.926,18.963,37.926z"
    />
    <rect
      x="37.926"
      y="37.926"
      style="fill:#FFDC64;"
      width="37.926"
      height="37.926"
    />
    <rect
      x="75.852"
      y="37.926"
      style="fill:#B4E66E;"
      width="37.926"
      height="37.926"
    />
    <g>
      <path
        style="fill:#C7CFE2;"
        d="M474.074,37.926h18.963c10.473,0,18.963,8.489,18.963,18.963v18.963h-37.926V37.926z"
      />
      <path
        style="fill:#C7CFE2;"
        d="M483.556,113.778H28.444c-5.236,0-9.481-4.245-9.481-9.481l0,0c0-5.236,4.245-9.481,9.481-9.481
		h455.111c5.236,0,9.481,4.245,9.481,9.481l0,0C493.037,109.532,488.792,113.778,483.556,113.778z"
      />
    </g>
    <g>
      <path
        style="fill:#FFFFFF;"
        d="M436.148,274.963h-56.889c-5.241,0-9.482-4.245-9.482-9.481s4.241-9.481,9.482-9.481h56.889
		c5.241,0,9.481,4.245,9.481,9.481S441.389,274.963,436.148,274.963z"
      />
      <path
        style="fill:#FFFFFF;"
        d="M379.269,237.037c-3.482,0-6.824-1.916-8.491-5.241c-2.343-4.685-0.444-10.38,4.241-12.722
		l37.926-18.963c4.676-2.347,10.37-0.44,12.722,4.241c2.343,4.685,0.444,10.38-4.241,12.722L383.5,236.037
		C382.139,236.717,380.695,237.037,379.269,237.037z"
      />
      <path
        style="fill:#FFFFFF;"
        d="M417.176,331.852c-1.426,0-2.871-0.32-4.231-1l-37.926-18.963
		c-4.685-2.343-6.584-8.037-4.241-12.722c2.351-4.69,8.027-6.584,12.722-4.241l37.926,18.963c4.685,2.343,6.584,8.037,4.241,12.722
		C424,329.935,420.658,331.852,417.176,331.852z"
      />
    </g>
    <g>
      <path
        style="fill:#707487;"
        d="M142.222,296.719v-29.842c-12.162-0.724-21.999-1.081-28.57-1.246
		c-5.279-0.132-9.356-2.207-9.356-4.73v23.865c0,5.045,4.077,9.197,9.356,9.461c6.201,0.312,15.374,0.984,26.594,2.274
		C140.923,296.581,141.53,296.638,142.222,296.719z"
      />
      <path
        style="fill:#707487;"
        d="M208.593,388.741h-4.741l-23.704-94.815h-37.926l26.649,106.596
		c1.056,4.222,4.847,7.182,9.198,7.182h30.523c5.236,0,9.481-4.245,9.481-9.481S213.829,388.741,208.593,388.741z"
      />
    </g>
    <path
      style="fill:#5B5D6E;"
      d="M180.148,293.926h-37.926l5.665,22.661c11.551,1.588,25.048,3.911,39.719,7.174L180.148,293.926z"
    />
    <path
      style="fill:#FFDC64;"
      d="M331.852,170.667h-18.963c-5.236,0-9.481,4.245-9.481,9.481v5.134
	c-79.247,42.34-162.359,50.075-189.755,51.452c-5.279,0.264-9.356,4.416-9.356,9.461v38.571c0,5.045,4.077,9.197,9.356,9.461
	c27.397,1.376,110.509,9.113,189.755,51.452v5.134c0,5.236,4.245,9.481,9.481,9.481h18.963c5.236,0,9.481-4.245,9.481-9.481V180.148
	C341.333,174.912,337.088,170.667,331.852,170.667z"
    />
    <path
      style="fill:#FFC850;"
      d="M303.407,185.286c-6.31,3.355-12.634,6.568-18.963,9.512v79.989c0,6.063-5.607,10.505-11.535,9.236
	c-69.112-14.789-135.368-17.792-159.257-18.393c-5.279-0.13-9.356-2.206-9.356-4.729v23.865c0,5.045,4.077,9.197,9.356,9.461
	c27.397,1.376,110.509,9.113,189.755,51.452V185.286z"
    />
    <path
      style="fill:#7F8499;"
      d="M142.222,234.244c-12.136,1.438-22.009,2.162-28.57,2.491c-5.279,0.264-9.356,4.416-9.356,9.461
	v38.571c0,5.045,4.077,9.197,9.356,9.461c6.562,0.329,16.435,1.054,28.57,2.491V234.244z"
    />
    <path
      style="fill:#707487;"
      d="M142.222,296.719v-29.838c-12.148-0.723-22.004-1.084-28.57-1.249
	c-5.279-0.132-9.356-2.207-9.356-4.73v23.865c0,5.045,4.077,9.197,9.356,9.461c6.201,0.312,15.374,0.984,26.594,2.274
	C140.923,296.581,141.53,296.638,142.222,296.719z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
